@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    margin: 0;
    padding: 0;
    outline: none;
}

.my-class, .my-partners-class{
    position:relative;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #F9F9F9 inset !important;
    -webkit-text-fill-color: #262626 !important;
}
/*scroll style*/
::-webkit-scrollbar {
    width: 4px;
    height: 1px;
}

::-webkit-scrollbar-thumb:horizontal {
    border-radius: 16px;
    -webkit-box-shadow: inset 0 0 5px #D9D9D9;
    background: lightgrey;
}

::-webkit-scrollbar-track:horizontal {
    -webkit-box-shadow: inset 0 0 5px #D9D9D9;
    border-radius: 16px;
    background: darkslategray;
}

::-webkit-scrollbar-thumb:vertical {
    border-radius: 16px;
    -webkit-box-shadow: inset 0 0 5px #D9D9D9;
    background: lightgrey;
}

::-webkit-scrollbar-track:vertical {
    -webkit-box-shadow: inset 0 0 5px white;
    border-radius: 16px;
}

/*---*/

.my-class:before,
.my-class:after{
    content: " ";
    position:absolute;
    background: #535353;
    height: 1px;
    right:4%;
    width:92%;
}
.my-class:before{top: -1px;}
.my-class:after{bottom: 0px;}


.my-class div:before,
.my-class div:after{
    content: " ";
    position:absolute;
    background: #535353;
    width: 1px;
    top:8%;
    height:84.5%;
}
.my-class div:before{left: -1px;}
.my-class div:after{right: 0px;}



.my-partners-class:before,
.my-partners-class:after{
    content: " ";
    position:absolute;
    background: #C9C9C9;
    height: 1px;
    right:8%;
    width:85%;
}
.my-partners-class:before{top: -1px;}
.my-partners-class:after{bottom: 0px;}


.my-partners-class div:before,
.my-partners-class div:after{
    content: " ";
    position: absolute;
    background: #C9C9C9;
    width: 1px;
    top:7%;
    height:86%;
}
.my-partners-class div:before{left: -1px;}
.my-partners-class div:after{right: 0px;}


.address-tab-line:before {
    content: '';
    width: 1px;
    background: #CECECE;
    height: 72px;
    margin: 0 20px;
}

@media screen and (min-width: 600px) {
    .address-tab-line:before {
        content: '';
        width: 1px;
        background: #CECECE;
        height: 72px;
        margin: 0 20px;
    }
}

@media screen and (max-width: 1000px) {
    .address-tab-line:before {
        content: '';
        height: 0;
        background: #CECECE;
        width: 0;
        margin: 0 20px;
        display: none;
    }
}


#map-report a img { display:none; }


input[type="time"]::-webkit-calendar-picker-indicator {
    color: red;
}

input[type="date"]::placeholder {
    color: red;
}


.sl .slick-track {
    display: flex;
    gap: 10px;
}

.pac-container { z-index: 100000; }


/*.jiMOeR .wrapper {*/
/*    position: absolute;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    width: 100%;*/
/*    border: none !important;*/
/*    border-bottom: 1px solid #E3E3E3 !important;*/
/*    border-radius: 0 !important;*/
/*    color: rgb(33, 33, 33);*/
/*    font-size: 16px;*/
/*    font-family: Arial;*/
/*    z-index: 0;*/
/*    background: #F9F9F9 !important;*/
/*}*/

/*.jiMOeR .wrapper:focus-within {*/
/*    box-shadow: none !important;*/
/*}*/

/*.jiMOeR {*/
/*    height: 32px !important;*/
/*}*/

/*.dYrfwz {*/
/*    min-height: 31px !important;*/
/*}*/


.tooltip:hover .tooltip-content {
    display: block;
}

html {
    scroll-behavior: smooth;
}
